<template>
  <div style="margin: 20px 30px;overflow-y: auto;" :style="{ height: het,width:wid }">
    <Title style="width:100%;text-align: center;">颐年健康隐私协议</Title>
    <div style="text-indent: 2em;">
          本政策仅适用于连云港颐年健康科技有限公司的颐年健康产品。
    </div>
    <div style="text-indent: 2em;">
          本应用尊重并保护所有使用服务用户的个人隐私权。本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。
      除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
      您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。
    </div>
    <div>1. 适用范围</div>
    <div>(a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；</div>
    <div>
      (b)
      在您使用本应用网络服务，或访问本应用平台网页时，本应用不接收也不记录您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
    </div>
    <div>(c) 本应用不获取用户个人数据。</div>
    <div>(d) 本应用提供账号注销功能，功能路径，在app里我的菜单下，找到系统设置下的账号安全，有注销账号功能，是电话注销，里面提供了注销电话。</div>
    <div>您了解并同意，以下信息不适用本隐私权政策：</div>
    <div>(a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；</div>
    <div>
      (b)
      本应用不收集到您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
    </div>
    <div>(c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。</div>
    <div>2. 信息使用</div>
    <div>
      (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
    </div>
    <div>
      (b)
      本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
    </div>
    <div>(c) 为服务用户的目的，本应用只是遥控设备APP。</div>
    <div>
      (d)为识别您的设备D并预防恶意程序及反作弊、提高服务安全性、保障运营质量及效率，我们(连云港颐年健康科技有限公司)会收集您的设备信息(包括IMEI、设备序列号、OAID、MEID、Android_ID、IMSI、GUID、MAC地址、SIM卡序列号、开启/关闭蓝牙、设备Wi-Fi信息)、已安装APP信息或运行中的进程信息、传感器信息、剪切板。
      <!-- <br/>(e)为实现垃圾清理与运行加速功能，我们将请求您的存储权限获取外置存储信息(SD卡数据)，用以检查手机CPU、内存和SD卡情况。
      <br/>(f)当你播放视频等内容时，为了适配你的设备状态，我们会调用设备的重力、加速度等传感器信息，以识别你的设备横竖屏状态 -->
      <br/>(e)在你分享或接收被分享的信息时，需要在本地访问你的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。
    </div>
    <div>3. 信息披露</div>
    <div>
      在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
    </div>
    <div>(a) 经您事先同意，向第三方披露；</div>
    <div>(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</div>
    <div>
      (c)
      根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
    </div>
    <div>
      (d)
      如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；
    </div>
    <div>
      (e)
      如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
    </div>
    <div>
      (f)
      在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
    </div>
    <div>(g) 其它本应用根据法律、法规或者网站政策认为合适的披露。</div>
    <div>(h) 本应用使用了微信登录的SDK,用户点击隐私政策协议“同意”前，SDK不能调用系统API接口获取用户个人信息、设备信息。</div>
    <div>4. 信息存储和交换</div>
    <div>
      本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
    </div>
    <div>5. 信息安全</div>
    <div>
      (a)
      本应用有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
    </div>
    <div>6.本隐私政策的更改</div>
    <div>
      (a)如果决定更改隐私政策，我们(连云港颐年健康科技有限公司)会在本政策中、本公司网站中认为适当的位置发布这些更改，以便您了解如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下会透露这些信息。
    </div>
    <div>
      (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
    </div>
    <div>
      (c)为识别您的设备D并预防恶意程序及反作弊、提高服务安全性、保障运营质量及效率，我们(连云港颐年健康科技有限公司)会收集您的设备信息(包括IMEI、设备序列号、OAID、MEID、Android lD.IMSI、GUID、MAC地址、SIM卡序列号)、已安装APP信息或运行中的进程信息。
      <br/>(d)在你分享或接收被分享的信息时，需要在本地访问你的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。
    </div>
    <div>
      请勿向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
    </div>
    <div>颐年健康app最新版本发布日期：2024-11-19</div>
    <div style="text-align: center;margin: 30px 0;">
        <!-- <Button type="primary" ghost  @click="reback" style="text-align: center;">返回</Button> -->
    </div>

  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
const het = window.innerHeight + "px";
const wid = window.innerWidth-50 + "px";


const reback = () =>{
  router.go(-1)
  //  router.push("/")
  // router.push({ path: '/privacyPolicy' })
}
</script>

<style scoped>
div{
    text-align: left;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 1px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 0;
}
</style>
